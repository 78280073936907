.header {
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #30485F;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  margin-bottom: 4rem;
}
.header-container {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.navigation-container {
   display: flex;
   gap: 2rem;
   color: #fff;
}
.navigation-container a {
   color: #fff;
}
.header-logo img {
   width: 13rem;
}
.burger-menu-trigger {
   display: none;
   height: 1.2rem;
   width: 2.6rem;
   flex-direction: column;
   justify-content: space-between;
   cursor: pointer;
   z-index: 1;

}
.burger-menu-trigger span {
   background-color: #fff;
   width: 100%;
   height: 2px;
   pointer-events: none;
   transition: transform .2s ease-in-out;
}
.burger-menu-trigger.open span:nth-child(1) {
   transform: rotate(45deg) translateX(.7rem);
}
.burger-menu-trigger.open span:nth-child(2) {
   transform: rotate(-45deg) translateX(.7rem);
}
.mobile-navigation-container {
   display: none;
   position: absolute;
   background-color: #9B9B9B;
   right: 0;
   top: 0;
   height: 100%;
   width: 45%;
   flex-direction: column;
   padding: 17rem 6rem;
   font-size: 2.8rem;
   justify-content: space-around;
   transform: translateX(60rem);
   transition: all ease-in-out .4s;

}
.mobile-navigation-container a {
   color: #fff;
}

@media (max-width: 700px) {
   .navigation-container {
      display: none;
   }
   .mobile-navigation-container {
      display: flex;
   }
   .mobile-navigation-container.open {
      transform: translateX(0)
   }
   .burger-menu-trigger{
      display: flex;
      
   }
}

/* ACTIVE NAVLINK CLASS */
.header a:not(.header-logo) {
   position: relative;
}
.header a::before {
   content: '';
   background-color: #E54A1E;
   height: .3rem;
   width: 0%;
   left:0;
   right:0 ;
   margin: auto;
   position: absolute;
   bottom: 0;
   transition: width .1s ease-in-out;
}
.header a:hover::before {
   width: 100%;
}
.header a.active::before {
   width: 100%;
}
