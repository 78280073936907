.container .filter-menu {
   position: absolute;
   top: 10rem;
   right: 10rem;
   max-width: 90%;
   background-color: rgba(255, 255, 255, 0.9);
   backdrop-filter: blur(5px);
   padding: 2rem;
   border-radius: 30px;
   z-index: 3;
   
}





