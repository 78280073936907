.players-container p {
   margin: 0;
   font-size: 1.8rem;
}

.players-container {
   display: flex;
   flex-wrap: wrap;
   gap: 2rem;
   justify-content: center;
}

.players-container .player {
   position: relative;
   background-color: #fff;
   max-width: 40rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1.5rem;
   justify-content: space-between;
   padding: 3rem;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 2.5px 7px;
   border-radius: 1.5rem;
}

.players-container .player img {
   max-width: 100%;
   border-radius: 100%;
   height: 34rem;
   width: 34rem;
   transition: all .2s ease-in-out;

}

.players-container .player .player__name,
.players-container .player .player__surname {
   font-weight: bold;
   font-size: 2.5rem;
   text-transform: capitalize;
}

.players-container .player .player__name {
   margin-bottom: -3rem;
}

.players-container .player .player__measures__caracteristics {
   display: flex;
   width: 80%;
   justify-content: space-between;
}

.players-container .player .player__measures,
.players-container .player .player__caracteristics {
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

.players-container .player .player__measures p,
.players-container .player .player__caracteristics p {
   display: flex;
   align-items: center;
   gap: 1rem;
}

.players-container .player .player__caracteristics p {
   display: flex;
   flex-direction: row-reverse;
   gap: 1rem;
}

.players-container .player .player__options {
   width: 100%;
   display: flex;
   justify-content: space-between;
   gap: 1rem;
}

.players-container .player .player__options .btn {
   width: 100%;
   cursor: pointer;
}

.player .player__info-btn {
   position: absolute;
   color: #E54A1E;
   font-size: 2.5rem;
   right: 20px;
   top: 20px;
}

.player.showAvailability.available img {
   box-shadow: rgba(9, 255, 0, 0.52) 0px 2px 4px 0px, rgba(9, 255, 0, 0.72) 0px 2px 16px 0px;

}

.player.showAvailability.not-available img {
   box-shadow: rgba(255, 50, 50, 0.52) 0px 2px 4px 0px, rgba(255, 50, 50, 0.72) 0px 2px 16px 0px;

}

.show-availability-container {
   display: flex;
   align-items: center;
   gap: 1rem;
}

.filter-trigger-button {
   background: none;
   border: none;
   font-size: 2rem;
   color: #555;
   cursor: pointer;
}
.players-top-section {
   display: flex;
   justify-content: space-between;
   margin-bottom: 2rem;
   padding: 0 2rem;
}
.filter-availability-container {
   display: flex;
   align-items: center;
   gap: 1rem;
}
.player__caracteristics {
   text-transform: capitalize;

}